import { apiSlice } from "../api/apiSlice";
// import mqtt from "mqtt/dist/mqtt";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: () => "/apiV2/dashboard",
      transformResponse: (response, meta, arg) => {
        function groupBy(arr, property) {
          return arr.reduce((result, obj) => {
            const key = obj[property];
            if (!result[key]) {
              result[key] = [];
            }
            result[key].push(obj);
            return result;
          }, {});
        }
        const keyValueData = Object.entries(response); //returns [key,value] pair
        let finalResponse = {};
        for (let [key, value] of keyValueData) {
          const groupByLevelData = {
            [key]: groupBy(value, "level"),
          };
          finalResponse = { ...finalResponse, ...groupByLevelData };
        }
        // console.log(JSON.stringify(finalResponse));

        return finalResponse;
      },
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        // console.log("onCacheEntryAdded");
        // let options = {
        //   clientId: Math.random(),
        //   username: "shahed",
        //   password: "Aqualink_321",
        //   // clean: true,
        //   reconnectPeriod: 1000,
        //   port: 8081,
        // };
        // let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
        // client.subscribe("/dashboard/data");
        // client.on("message", (topic, message) => {
        //   console.log(topic, message.toString());
        //   console.log("Inside mqtt listen");
        //   //  console.log(dashboardApi.getDashboardData);

        //   updateCachedData((draft) => {
        //     // draft.push({"murgirDim":52});
        //     // console.log(JSON.stringify(draft));
        //   });
        // });
      },
    }),
  }),
});

export const { useGetDashboardDataQuery, useLazyGetDashboardDataQuery } =
  dashboardApi;
