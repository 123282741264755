import CompB from "../components/CompB";
import CompC from "../components/CompC";
import BaseLayout from "../layout/BaseLayout";
import CompD from "../components/CompD";
import { Outlet } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import { FaBeer, FaDoorClosed, FaBell, FaCity } from "react-icons/fa";
import { RiDashboardFill, RiSensorFill, RiDeviceFill } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi";
import { AiFillControl } from "react-icons/ai";

import { MdAssignment } from "react-icons/md";
import { BsFillBarChartFill } from "react-icons/bs";
import { AiTwotoneNotification } from "react-icons/ai";
import TestComponent from "../components/TestComponent";
import AlertSettings from "../components/alert/alertSettings";
import CreateSensor from "../components/sensor/createSensor";
// import SensorMap from "../components/sensor/sensorMap";
import CreatePremises from "../components/premises/createPremises";
import ListPremises from "../components/premises/listPremises";
import DeviceConfiguration from "../components/device/deviceConfiguration";
import RoomConfiguration from "../components/premises/roomConfiguration";
// import Building from "../components/premises/building";
import { ImDatabase } from "react-icons/im";
import DataLogMain from "../components/dataLog/DataLogMain";
import RoomDetailsMain from "../components/dashboard/roomDetails/RoomDetailsMain";
import BasicTableComponent from "../components/BasicTableComponent";
import Login from "../components/auth/Login";
import PrivateRoute from "../components/PrivateRoute";
import PublicRoute from "../components/PublicRoute";
import Logout from "../components/auth/Logout";
import Premises from "../components/premises/premisesCreate/Premises";
import EnergyDashboard from "../components/energyMeter/EnergyDashboard";
import { ImPower } from "react-icons/im";
import EnergyDetailsMain from "../components/energyMeter/details/EnergyDetailsMain";
import Rooms from "../components/rooms/Rooms";
import Building from "../components/building/Building";
import SlaveAssign from "../components/slaveAssign/SlaveAssign";
import DeviceConfig from "../components/device-config/DeviceConfig";
import SensorMap from "../components/sensor-map/SensorMap";
import MotorControl from "../components/motor-control/MotorControl";
import NotificationMain from "../components/notification/NotificationMain";

//first route shouldnot have children might create issue on sidebar

export const routes = [
  {
    title: "Default",
    path: "/",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    title: "Login",
    path: "/login",
    icon: <RiDashboardFill />,
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <RiDashboardFill />,
    visibility: true,
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  // {
  //   title: "Motor Control",
  //   path: "/motor-control",
  //   icon: <AiFillControl />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       <MotorControl />
  //     </PrivateRoute>
  //   ),
  // },
  {
    title: "Data Log",
    path: "/data_log",
    icon: <ImDatabase />,
    visibility: true,
    element: (
      <PrivateRoute>
        {" "}
        <DataLogMain />
      </PrivateRoute>
    ),
  },
  {
    title: "Notification Log",
    path: "/notification",
    icon: <AiTwotoneNotification />,
    visibility: true,
    element: (
      <PrivateRoute>
        {" "}
        <NotificationMain />
      </PrivateRoute>
    ),
  },
  {
    title: "Room Details",
    path: "/dashboard/:roomId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <RoomDetailsMain />
      </PrivateRoute>
    ),
  },
  // {
  //   title: "Energy Dashboard",
  //   path: "/energy_meter_dashboard",
  //   icon: <ImPower />,
  //   visibility: true,
  //   element: (
  //     <PrivateRoute>
  //       {" "}
  //       <EnergyDashboard />
  //     </PrivateRoute>
  //   ),
  // },
  {
    title: "Energy Meter Details",
    path: "/energy_meter_dashboard/:meterId",
    icon: <ImDatabase />,
    visibility: false,
    element: (
      <PrivateRoute>
        <EnergyDetailsMain />
      </PrivateRoute>
    ),
  },

  // {
  //   title: "Basic Table",
  //   path: "/table",
  //   icon: <ImDatabase />,
  //   visibility:true,
  //   element: <BasicTableComponent />,
  // },
  // {
  //   title: "COMPB",
  //   path: "/compB",
  //   icon: <BsFillBarChartFill />,

  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "COMPD",
  //       path: "compD",
  //       icon: <FaBeer />,
  //       element: <CompD />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "COMPD",
  //       path: "compD",
  //       icon: <FaBeer />,
  //       element: <CompD />,
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "COMPC",
  //   path: "/compC",
  //   icon: <FaBeer />,
  //   element: <CompC />,
  // },
  // {
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: <RiDashboardFill />,
  //   element: <Dashboard />,
  // },
  // {
  //   title: "COMPB",
  //   path: "/compB",
  //   icon: <BsFillBarChartFill />,
  //   // element:<CompB/>,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "COMPD",
  //       path: "compD",
  //       icon: <FaBeer />,
  //       element: <CompD />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "COMPD",
  //       path: "compD",
  //       icon: <FaBeer />,
  //       element: <CompD />,
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "COMPB",
  //   path: "/compB",
  //   icon: <BsFillBarChartFill />,
  //   // element:<CompB/>,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "COMPB",
  //       path: "compB",
  //       icon: <FaBeer />,
  //       element: <CompB />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "COMPD",
  //       path: "compD",
  //       icon: <FaBeer />,
  //       element: <CompD />,
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  // {
  //   title: "Light Control",
  //   path: "/lt",
  //   icon: <BsFillBarChartFill />,
  //   // element:<CompB/>,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "child1",
  //       path: "child_1",
  //       icon: <FaBeer />,
  //       element: <TestComponent />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "child2",
  //       path: "compD",
  //       icon: <FaBeer />,
  //       element: <CompD />,
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  {
    title: "Alert Settings",
    path: "/alert",
    icon: <FaBell />,
    visibility: true,
    element: (
      <PrivateRoute>
        <AlertSettings />
      </PrivateRoute>
    ),
  },
  {
    title: "Sensor Management",
    path: "/sensor",
    icon: <RiSensorFill />,
    visibility: true,
    children: [
      // { index: true , element:<CompB/>},
      {
        title: "Create Sensor",
        path: "create-sensor",
        // icon: <FaBeer />,
        element: (
          <PrivateRoute>
            <CreateSensor />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      // {
      //   title: "Sensor Map",
      //   path: "map-sensor",
      //   // icon: <FaBeer />,
      //   element: <SensorMap />,
      //   // loader: teamLoader,
      // },
    ],
  },
  // {
  //   title: "Premises  ",
  //   path: "/premises",
  //   icon: <FaCity />,
  //   visibility: true,
  //   children: [
  //     // { index: true , element:<CompB/>},
  //     {
  //       title: "Create Premises ",
  //       path: "create-premises",
  //       icon: <FaBeer />,
  //       element: <CreatePremises />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "List  Premises ",
  //       path: "list-premises",
  //       // icon: <FaBeer />,
  //       element: <ListPremises />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Building ",
  //       path: "building",
  //       // icon: <FaBeer />,
  //       element: <Building />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Room  Configurations ",
  //       path: "room-configuration",
  //       // icon: <FaBeer />,
  //       element: <RoomConfiguration />,
  //       // loader: teamLoader,
  //     },
  //     {
  //       title: "Sensor Map ",
  //       path: "sensor-map",
  //       // icon: <FaBeer />,
  //       element: <SensorMap />,
  //       // loader: teamLoader,
  //     },
  //   ],
  // },
  {
    title: "Premises  ",
    path: "/premises",
    icon: <FaCity />,
    visibility: true,

    children: [
      // { index: true , element:<CompB/>},
      {
        title: "Create Premises ",
        path: "create-premises",
        icon: <FaBeer />,
        element: (
          <PrivateRoute>
            <Premises />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Create Building",
        path: "building-create",
        icon: <FaBeer />,
        element: (
          <PrivateRoute>
            <Building />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Create Room ",
        path: "room-create",
        icon: <FaBeer />,
        element: (
          <PrivateRoute>
            <Rooms />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },
  // {
  //   title: "Device Configuration",
  //   path: "/device-configuration",
  //   icon: <RiDeviceFill />,
  //   visibility:true,
  //   element: <DeviceConfiguration />,
  // },
  {
    title: "Slave Assign",
    path: "/slave-assign",
    icon: <MdAssignment />,
    visibility: true,
    children: [
      // { index: true , element:<CompB/>},
      {
        title: "Device Assign",
        path: "device-assign",
        element: (
          <PrivateRoute>
            <SlaveAssign />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
      {
        title: "Sensor Map",
        path: "sensor-map",
        element: (
          <PrivateRoute>
            <SensorMap />
          </PrivateRoute>
        ),
        // loader: teamLoader,
      },
    ],
  },
  {
    title: "Device Configuration",
    path: "/device-config",
    icon: <RiDeviceFill />,
    visibility: true,
    element: <DeviceConfig />,
  },

  {
    title: "logout",
    path: "/logout",
    icon: <FiLogOut />,
    visibility: true,
    element: <Logout />,
  },
  {
    path: "*",
    visibility: false,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  }, //to redirect unknown routes during login
];
