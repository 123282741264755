import React, { useState, useEffect, useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import {
  useGetDashboardDataQuery,
  useLazyGetDashboardDataQuery,
} from "../../features/dashboard/dashboardApi";
import "./style/dashboard.css";
import ReactLoading from "react-loading";
import timeAgo from "../../helper/timeAgo";
import mqtt from "mqtt/dist/mqtt";
import negativeValueCalculate from "../../helper/negativeValueCalculate";
import { Link, useNavigate } from "react-router-dom";
import { generatePath } from "react-router-dom";
import DangerStatus from "./thresholdStatus/DangerStatus";
import SafeStatus from "./thresholdStatus/SafeStatus";
import WarningStatus from "./thresholdStatus/WarningStatus";
import thresholdStatusCalculate from "../../helper/thresholdStatusCalculate";

function Dashboard() {
  let { data, isLoading } = useGetDashboardDataQuery();
  const theme = useContext(ThemeContext);
  const [getDashboardData, results] = useLazyGetDashboardDataQuery();
  const navigate = useNavigate();
  // console.log(results.data);

  // //mqtt code starts
  let options = {
    clientId: Math.random(),
    username: "shahed",
    password: "Aqualink_321",
    // clean: true,
    reconnectPeriod: 1000,
    port: 8081,
  };

  let client = mqtt.connect("mqtt://mqtt.aqualinkbd.com", options);
  useEffect(() => {
    client.subscribe("/dashboard/data");

    // Update the document title using the browser API
    client.on("message", (topic, message) => {
      console.log(topic, message.toString());
      getDashboardData();
    });
  }, [client]);
  // // mqtt code ends

  const roomClickHandler = (room_name) => {
    const path = generatePath("/dashboard/:roomId", { roomId: room_name });
    navigate(path);
  };

  let dashboardData = "";
  let element = "";
  if (isLoading) {
    element = (
      <div className="container loading-center">
        <ReactLoading type="spin" color="#6d6dae" height={100} width={50} />
      </div>
    );
  }
  if (!isLoading) {
    if (results?.data) {
      data = results?.data;
    }

    // console.log((data && data) || {});
    dashboardData = data && Object.entries(data);
    element = (
      <div className={`container-fluid ${theme}`}>
        <div className="row">
          {dashboardData ? (
            dashboardData.map(([room, value]) => {
              let p = "";
              // console.log(value);
              if (Object.keys(value).length > 0) {
                p = new Date(Object.values(value)[0][0]?.updated_at);
              }
              // const formattedDate = p.toLocaleString();
              let time_Ago = "";
              if (p) {
                time_Ago = timeAgo(p);
              }
              //from helper function
              const [roomName, roomId] = room?.split("|");

              return (
                <div class="col-md-6">
                  <div
                    className="room-card "
                    onClick={() => roomClickHandler(roomId)}
                  >
                    <h4 className="room-name">
                      {roomName}({time_Ago})
                    </h4>
                    <div className="grid">
                      {Object.entries(value).map(([level, value]) => {
                        return (
                          <div className="theme-text">
                            <div>
                              <h5>Level-{level}</h5>
                              <table style={{ width: "100%" }}>
                                <tr>
                                  {/* <th>Level</th> */}
                                  <th>Slave </th>
                                  <th>Sensor</th>
                                  <th>Value</th>
                                  <th>Status</th>
                                </tr>

                                {value.map((item) => {
                                  return (
                                    <tr>
                                      {/* <td>Level-{item.level}</td> */}
                                      <td>{item.slave_address}</td>
                                      <td>{item.sensor[0].sensor_name}</td>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {item?.value > 6000
                                          ? negativeValueCalculate(item.value)
                                          : item?.value}{" "}
                                        {""}
                                        {item.sensor[0].unit}
                                      </td>
                                      <td className="threshold-status">
                                        {thresholdStatusCalculate(
                                          item?.value,
                                          item?.threshold[0]
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="theme-text">No Data Available</div>
          )}
        </div>
      </div>
    );
  }

  return <>{element}</>;
}

export default Dashboard;
